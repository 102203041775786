import React, { PureComponent } from 'react';
import Loadable from 'react-loadable';
// import dynamic from 'next/dynamic'
import Layout from '../../components/Layouts';

export const frontmatter = {
    title: `Marvel Cinematic Universe Movies`,
    written: `2019-05-04`,
    updated: `2019-05-04`,
    layoutType: 'post',
    contentType: `dataviz`,
    path: `/mcuboxoffice/`,
    category: `VISUALISATION`,
    // image: `./poster.png`,
    description: `Box Office Performance of Marvel Movies`
  }

//   const DynamicPlot = dynamic(import('./MCUPlot'), {
//     ssr: false
//   })

//   const DynamicPlot = <div></div>

//   export default class MCUBoxOffice extends Component {
//     render() {
//         return (
//             <div>
//                 <DynamicPlot />
//             </div>
//         )
//     }
// }


const LoadableComponent = Loadable({
    loader: () => import('./MCUPlot'),
    loading() {
        return <div>Loading....</div>
    }
})

export default class MCUBoxOffice extends PureComponent {
    render() {
        return (
            <Layout data={this.props.data} location={this.props.location}>
            <div>
                <LoadableComponent></LoadableComponent>
            </div>
            </Layout>
        )
    }
}

// export default class MCUBoxOffice extends Component {
//     state = {
//         MCUPlot: null
//     };

//     componentDidMount() {
//         import('./MCUPlot').then(MCUPlot => {
//             this.setState({MCUPlot: MCUPlot.default});
//         });
//         // let MCUPlot = dynamic(import('./MCUPlot'), {
//         //     ssr: false
//         //   });
//         //   this.setState({MCUPlot});
//     }
//     render() {
//         let { MCUPlot } = this.state;
//         if (!MCUPlot) {
//             return <div>Unable to load the page</div>;
//         } else {
//             return <MCUPlot />;
//         }
//     }
// }